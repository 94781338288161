@font-face {
	font-family: 'AcuminPro-Bold';
	src: local('AcuminPro-Bold'),
		url('../assets/fonts/AcuminPro-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Helvetica-Regular';
	src: local('Helvetica-Regular'),
		url('../assets/fonts/Helvetica-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Helvetica-Bold';
	src: local('Helvetica-Bold'),
		url('../assets/fonts/Helvetica-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'HelveticaNeue-Bold';
	src: local('HelveticaNeue-Bold'),
		url('../assets/fonts/HelveticaNeue-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'HelveticaNeue-Regular';
	src: local('HelveticaNeue-Regular'),
		url('../assets/fonts/HelveticaNeue-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'HelveticaNeue-Light';
	src: local('HelveticaNeue-Light'),
		url('../assets/fonts/HelveticaNeue-Light.ttf') format('truetype');
}

@font-face {
	font-family: 'HelveticaNeue-Medium';
	src: local('HelveticaNeue-Medium'),
		url('../assets/fonts/HelveticaNeue-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'ByulbitchaR';
	src: local('ByulbitchaR'),
		url('../assets/fonts/BMEULJIROTTF.ttf') format('truetype');
}

@font-face {
	font-family: 'AppleLiGothicMedium';
	src: local('AppleLiGothicMedium'),
		url('../assets/fonts/AppleLiGothic-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'Arial-Regular';
	src: local('Arial-Regular'),
		url('../assets/fonts/Arial-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'NotoSansKR-Black';
	src: local('NotoSansKR-Black'),
		url('../assets/fonts/NotoSansKR-Black.ttf') format('truetype');
}

@font-face {
	font-family: 'NotoSansKR-Bold';
	src: local('NotoSansKR-Bold'),
		url('../assets/fonts/NotoSansKR-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'NotoSansKR-ExtraBold';
	src: local('NotoSansKR-ExtraBold'),
		url('../assets/fonts/NotoSansKR-ExtraBold.ttf') format('truetype');
}

@font-face {
	font-family: 'NotoSansKR-ExtraLight';
	src: local('NotoSansKR-ExtraLight'),
		url('../assets/fonts/NotoSansKR-ExtraLight.ttf') format('truetype');
}

@font-face {
	font-family: 'NotoSansKR-Light';
	src: local('NotoSansKR-Light'),
		url('../assets/fonts/NotoSansKR-Light.ttf') format('truetype');
}

@font-face {
	font-family: 'NotoSansKR-Medium';
	src: local('NotoSansKR-Medium'),
		url('../assets/fonts/NotoSansKR-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'NotoSansKR-Regular';
	src: local('NotoSansKR-Regular'),
		url('../assets/fonts/NotoSansKR-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'NotoSansKR-SemiBold';
	src: local('NotoSansKR-SemiBold'),
		url('../assets/fonts/NotoSansKR-SemiBold.ttf') format('truetype');
}

@font-face {
	font-family: 'NotoSansKR-Thin';
	src: local('NotoSansKR-Thin'),
		url('../assets/fonts/NotoSansKR-Thin.ttf') format('truetype');
}

@font-face {
	font-family: 'OneMobilePop';
	src: local('OneMobilePop'),
		url('../assets/fonts/OneMobilePop.ttf') format('truetype');
}

@font-face {
	font-family: 'NotoSansMyanmar-Bold';
	src: local('NotoSansMyanmar-Bold'),
		url('../assets/fonts/NotoSansMyanmar-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'NotoSansMyanmar-Regular';
	src: local('NotoSansMyanmar-Regular'),
		url('../assets/fonts/NotoSansMyanmar-Regular.ttf') format('truetype');
}
